const headings = {
    h1: {
      fontSize: [8,null,11],
      lineHeight: "h1",
      fontFamily: "heading",
      fontWeight: "400",
      color: "secondary",
    },
    h2: {
      fontSize: [5, null, 9],
      lineHeight: "h2",
      fontFamily: "heading",
      fontWeight: "400",
      color: "secondary",
    },
    h3: {
      fontSize: [4, null, 7],
      lineHeight: "h3",
      fontFamily: "heading",
      fontWeight: "400",
      color: "secondary",
    },
    h4: {
      fontSize: 6,
      fontFamily: "heading",
      fontWeight: "400",
      color: "secondary",
    },
    h5: {
      fontSize: 3,
      fontFamily: "heading",
      fontWeight: "400",
      color: "secondary",
    },
    h6: {
      fontSize: 2,
      fontFamily: "heading",
      fontWeight: "400",
      color: "secondary",
    },
    a: {
      color: "primary",
      opacity: "1",
      transition: "all 0.3s ease",
      "&:hover": {
        opacity: "0.8",
      },
    },
  }
  export default {
    breakpoints: ["768px", "992px", "1200px"],
    colors: {
      text: "#434a56",
      background: "#ffffff",
      primary: "#0a39f5",
      secondary: "#13263a",
      highlight: "#FBFBFB",
      tertiary: "#f7f7f7",
    },
    fonts: {
      heading: "'Crimson Text', serif",
      body: "'Nunito Sans', sans-serif",
    },
    fontSizes: [14,16,17,21,31,40,42,44,51,57,63,72],
    lineHeights: {
      body: "1.8",
      h1: "1.15",
      h2: "1.14",
      h3: "1.14",
    },
    text: {
      ...headings,      
      btn: {
        fontSize: 3,
        fontWeight: "500",
        textDecoration: "none",
        transition: "all 0.3s ease",
        lineHeight: "1",
        fontFamily: "heading",
      },
    },
    forms:{
      label: {
        fontSize: 1,
        lineHeight: '1.87',
        color: 'secondary',
      },
      input: {
        borderColor: 'secondary',
        borderWidth: '2px',
        borderStyle: 'solid',
        p:'13px',
        lineHeight: '1',
        '&:focus': {         
          outline: 'none',
        },
      },
      textarea: {
        borderColor: 'secondary',
        borderWidth: '2px',
        borderStyle: 'solid',
        p:'13px',
        lineHeight: '1',
        height: '200px',
        '&:focus': {         
          outline: 'none',
        },
      },      
    },
    buttons: {
      primary: {
        p: "22.4px 36px",
        borderRadius: "4px",
        display: ["block", null, "inline-block"],
        opacity: "1",
        textAlign: "center",
        cursor: "pointer",
        backgroundColor: "primary",
        color: "background",
        border: "2px solid",
        borderColor: "primary",
        fontSize: 3,
        lineHeight: '1',
        fontFamily: 'heading',
        "&:hover": {
          backgroundColor: "secondary",
          borderColor: "secondary"
        },
      },      
    },
    styles: {
      root: {
        fontSize: 2,
        lineHeight: "body",
        fontFamily: "body",
        fontWeight: "400",
      },
      ...headings,
      img: {
        maxWidth: "100%",
        height: "auto",
      },
    },
  }